<template>
  <div>
    <synchronization-list-steps-add
      :hormones="hormones"
      :is-add-sync-modal-active.sync="isAddSyncModalActive"
      :methods="methods"
      :sync-id="synchronization.id"
      @refetch-data="fetchSteps"
    />
    <synchronization-list-steps-edit
      :is-edit-sync-modal-active.sync="isEditSyncModalActive"
      :sync-id="synchronization.id"
      :step="selectedStep"
      :methods="methods"
      :hormones="hormones"
      @refetch-data="fetchSteps"
    />
    <b-modal
      id="edit-synchronization-modal"
      centered
      :visible="isEditSynchronizationModalActive"
      bg-variant="white"
      shadow
      size="xl"
      backdrop
      right
      :title="synchronization.syncName + ' ' + $t('synchronizationSteps')"
      :ok-title="$t('ok')"
      ok-only
      @ok="$refs.submitButtonRef.click()"
      @hidden="formValidation(resetblanksynchronization).resetForm"
      @change="(val) => changed(val)"
      @show="(val) => changed(true)"
    >
      <div class="overflow-auto">
        <div class="d-flex mb-2 align-items-end justify-content-end">
          <b-button
            v-if="synchronization.allowedit"
            variant="success"
            class="d-flex align-items-center justify-content-between"
            @click="openAddSyncStepModal"
          >
            <feather-icon icon="PlusIcon" size="20" />
            <span> {{ $t("addStep") }} </span>
          </b-button>
        </div>
        <b-table :items="steps" :fields="fields">
          <template #cell(actions)="{ item }">
            <div class="d-flex">
              <b-link
                v-if="synchronization.allowedit"
                class="text-primary mr-1"
                @click="openEditSyncStepModal(item)"
              >
                <div class="d-flex">
                  <feather-icon icon="EditIcon" />
                  <small class="align-middle ml-50 text-dark">{{
                    $t("edit")
                  }}</small>
                </div>
              </b-link>
              <b-link
                v-if="synchronization.allowdelete"
                class="text-danger"
                @click="deleteSyncStep(item)"
              >
                <div class="d-flex">
                  <feather-icon icon="TrashIcon" />
                  <small class="align-middle ml-50 text-dark">{{
                    $t("delete")
                  }}</small>
                </div>
              </b-link>
            </div>
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton, BTable, BLink } from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import SynchronizationListStepsAdd from "./SynchronizationListStepsAdd.vue";
import SynchronizationListStepsEdit from "./SynchronizationListStepsEdit.vue";
export default {
  components: {
    BModal,
    BTable,
    BButton,
    BLink,

    SynchronizationListStepsAdd,
    SynchronizationListStepsEdit,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditSynchronizationModalActive",
    event: "update:is-edit-synchronization-modal-active",
  },
  props: {
    isEditSynchronizationModalActive: {
      type: Boolean,
      required: true,
    },
    synchronization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isAddSyncModalActive: false,
      isEditSyncModalActive: false,
      selectedStep: {},
      blankSynchronizationData: { ...this.synchronization },
      formValidation: formValidation,
      fields: [
        { key: "methodname", label: this.$t("methodName") },
        {
          key: "daysafter",
          label: this.$t("daysAfter"),
          formatter: (value, a, b) => {
            if (value == 0) {
              return this.$t("firstApply");
            }
            return this.$t("afterTime", {
              day: b["daysafter"],
              hour: b["hoursafter"],
            });
          },
        },
        { key: "hormonename", label: this.$t("hormoneName") },
        { key: "defaultdoze", label: this.$t("doze") },
        { key: "description", label: this.$t("description") },
        { key: "actions", label: this.$t("actions") },
      ],
      steps: [],
      methods: [],
      hormones: [],
    };
  },
  async mounted() {
    this.fetchMethods();
    this.fetchHormones();
  },
  methods: {
    openAddSyncStepModal(step) {
      this.isAddSyncModalActive = true;
    },
    openEditSyncStepModal(item) {
      this.selectedStep = item;
      this.isEditSyncModalActive = true;
    },
    async deleteSyncStep(item) {
      try {
        var response = await store.dispatch(
          "definitionsModule/deleteSyncStep",
          item.id
        );
        if (response) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$t("successful"),
              icon: "CheckIcon",
              variant: "success",
              text: this.$t("deleted", { type: this.$t("syncStep") }),
            },
          });
          this.fetchSteps();
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: this.$t("notDeleted", { type: this.$t("syncStep") }),
          },
        });
      }
    },
    async fetchSteps() {
      this.steps = await store.dispatch(
        "definitionsModule/fetchSynchronizationStepsBySyncId",
        this.blankSynchronizationData.id
      );
    },
    async fetchMethods() {
      this.methods = await store.dispatch("definitionsModule/fetchSyncMethods");
    },
    async fetchHormones() {
      this.hormones = await store.dispatch(
        "definitionsModule/fetchSyncHormones"
      );
    },
    async changed(val) {
      this.resetblanksynchronization();
      await this.fetchSteps();
      this.$emit("update:is-edit-synchronization-modal-active", val);
    },
    onSubmit() {
      this.blankSynchronizationData.imgstr = this.imgStr;
      store
        .dispatch(
          "definitionsModule/updateSynchronization",
          this.blankSynchronizationData
        )
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-synchronization-modal-active", false);
        });
    },

    async resetblanksynchronization() {
      this.blankSynchronizationData = { ...this.synchronization };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-synchronization-modal {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
