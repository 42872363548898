<template>
  <div><synchronization-list /></div>
</template>

<script>
import SynchronizationList from "./components/StepList/SynchronizationList.vue";
export default {
  components: { SynchronizationList },
};
</script>
<style></style>
