var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"edit-synchronization-modal","centered":"","visible":_vm.isAddSyncModalActive,"bg-variant":"white","shadow":"","size":"xl","backdrop":"","right":"","ok-only":"","ok-title":_vm.$t('ok'),"title":_vm.$t('addSyncStep')},on:{"hidden":function($event){_vm.formValidation(_vm.resetblanksynchronization).resetForm},"change":function (val) { return _vm.changed(val); },"show":function (val) { return _vm.changed(true); },"ok":function () { return _vm.onSubmit(); }}},[_c('div',{staticClass:"overflow-auto"},[_c('validation-observer',{ref:"formValidation(\n    resetblanksynchronization\n  ).refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{ref:"refForm",staticClass:"p-2 modal-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('button',{ref:"submitButtonRef",staticClass:"d-none",attrs:{"type":"submit"}}),_c('validation-provider',{attrs:{"rules":"required","name":"method"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('syncMethod'),"label-for":"method"}},[_c('b-form-select',{attrs:{"id":"amethod","state":_vm.formValidation(_vm.resetblanksynchronization).getValidationState(
                  validationContext
                ),"options":_vm.methods.map(function (method) { return ({
                  text: method.methodname,
                  value: method.methodid,
                }); }),"placeholder":_vm.$t('methodName')},model:{value:(_vm.blankSyncStepData.methodid),callback:function ($$v) {_vm.$set(_vm.blankSyncStepData, "methodid", $$v)},expression:"blankSyncStepData.methodid"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"hormone"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('hormoneName'),"label-for":"hormone"}},[_c('b-form-select',{attrs:{"id":"ahormone","state":_vm.formValidation(_vm.resetblanksynchronization).getValidationState(
                  validationContext
                ),"options":_vm.hormones.map(function (hormone) { return ({
                  text: hormone.hormonename ? hormone.hormonename : '-',
                  value: hormone.id,
                }); }),"placeholder":_vm.$t('hormoneName')},model:{value:(_vm.blankSyncStepData.hormonid),callback:function ($$v) {_vm.$set(_vm.blankSyncStepData, "hormonid", $$v)},expression:"blankSyncStepData.hormonid"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"","name":"syncDescription"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('description'),"label-for":"syncDescription"}},[_c('b-form-input',{attrs:{"id":"asyncDescription","autofocus":"","state":_vm.formValidation(_vm.resetblanksynchronization).getValidationState(
                  validationContext
                ),"trim":"","placeholder":""},model:{value:(_vm.blankSyncStepData.description),callback:function ($$v) {_vm.$set(_vm.blankSyncStepData, "description", $$v)},expression:"blankSyncStepData.description"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"daysAfter"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('daysAfter'),"label-for":"daysAfter"}},[_c('b-form-input',{attrs:{"id":"adaysAfter","autofocus":"","state":_vm.formValidation(_vm.resetblanksynchronization).getValidationState(
                  validationContext
                ),"trim":"","placeholder":"","type":"number"},model:{value:(_vm.blankSyncStepData.daysafter),callback:function ($$v) {_vm.$set(_vm.blankSyncStepData, "daysafter", $$v)},expression:"blankSyncStepData.daysafter"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"hoursAfter"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('hoursAfter'),"label-for":"hoursAfter"}},[_c('b-form-input',{attrs:{"id":"ahoursAfter","autofocus":"","state":_vm.formValidation(_vm.resetblanksynchronization).getValidationState(
                  validationContext
                ),"trim":"","placeholder":"","type":"number"},model:{value:(_vm.blankSyncStepData.hoursafter),callback:function ($$v) {_vm.$set(_vm.blankSyncStepData, "hoursafter", $$v)},expression:"blankSyncStepData.hoursafter"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"defaultDoze"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('defaultDoze'),"label-for":"defaultDoze"}},[_c('b-form-input',{attrs:{"id":"adefaultDoze","autofocus":"","state":_vm.formValidation(_vm.resetblanksynchronization).getValidationState(
                  validationContext
                ),"trim":"","placeholder":"","type":"number"},model:{value:(_vm.blankSyncStepData.defaultdoze),callback:function ($$v) {_vm.$set(_vm.blankSyncStepData, "defaultdoze", $$v)},expression:"blankSyncStepData.defaultdoze"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }