<template>
  <b-modal
    id="edit-synchronization-modal"
    centered
    :visible="isAddSyncModalActive"
    bg-variant="white"
    shadow
    size="xl"
    backdrop
    right
    ok-only
    :ok-title="$t('ok')"
    :title="$t('addSyncStep')"
    @hidden="formValidation(resetblanksynchronization).resetForm"
    @change="(val) => changed(val)"
    @show="(val) => changed(true)"
    @ok="() => onSubmit()"
  >
    <div class="overflow-auto">
      <validation-observer
        #default="{ handleSubmit }"
        ref="formValidation(
      resetblanksynchronization
    ).refFormObserver"
      >
        <!-- Form -->

        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <!-- Method -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="method"
          >
            <b-form-group :label="$t('syncMethod')" label-for="method">
              <b-form-select
                id="amethod"
                v-model="blankSyncStepData.methodid"
                :state="
                  formValidation(resetblanksynchronization).getValidationState(
                    validationContext
                  )
                "
                :options="
                  methods.map((method) => ({
                    text: method.methodname,
                    value: method.methodid,
                  }))
                "
                :placeholder="$t('methodName')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Hormone -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="hormone"
          >
            <b-form-group :label="$t('hormoneName')" label-for="hormone">
              <b-form-select
                id="ahormone"
                v-model="blankSyncStepData.hormonid"
                :state="
                  formValidation(resetblanksynchronization).getValidationState(
                    validationContext
                  )
                "
                :options="
                  hormones.map((hormone) => ({
                    text: hormone.hormonename ? hormone.hormonename : '-',
                    value: hormone.id,
                  }))
                "
                :placeholder="$t('hormoneName')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- syncDescription -->
          <validation-provider
            #default="validationContext"
            rules=""
            name="syncDescription"
          >
            <b-form-group
              :label="$t('description')"
              label-for="syncDescription"
            >
              <b-form-input
                id="asyncDescription"
                v-model="blankSyncStepData.description"
                autofocus
                :state="
                  formValidation(resetblanksynchronization).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- daysAfter -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="daysAfter"
          >
            <b-form-group :label="$t('daysAfter')" label-for="daysAfter">
              <b-form-input
                id="adaysAfter"
                v-model="blankSyncStepData.daysafter"
                autofocus
                :state="
                  formValidation(resetblanksynchronization).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
                type="number"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- hoursAfter -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="hoursAfter"
          >
            <b-form-group :label="$t('hoursAfter')" label-for="hoursAfter">
              <b-form-input
                id="ahoursAfter"
                v-model="blankSyncStepData.hoursafter"
                autofocus
                :state="
                  formValidation(resetblanksynchronization).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
                type="number"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- defaultDoze -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="defaultDoze"
          >
            <b-form-group :label="$t('defaultDoze')" label-for="defaultDoze">
              <b-form-input
                id="adefaultDoze"
                v-model="blankSyncStepData.defaultdoze"
                autofocus
                :state="
                  formValidation(resetblanksynchronization).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
                type="number"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormInvalidFeedback,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
    BFormSelect,

    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddSyncModalActive",
    event: "update:is-add-sync-modal-active",
  },
  props: {
    isAddSyncModalActive: {
      type: Boolean,
      required: true,
    },
    syncId: {
      type: Number || String,
      required: true,
    },

    methods: {
      type: Array,
      required: true,
    },
    hormones: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      blankSyncStepData: {
        syncid: this.syncId,
        methodid: this.methods[0]?.id ?? 0,
        hormonid: this.hormones[0]?.id ?? 0,
        daysafter: null,
        hoursafter: null,
        defaultdoze: null,
        description: "",
      },
      formValidation: formValidation,
      fields: [
        { key: "methodname", label: this.$t("methodName") },
        {
          key: "daysafter",
          label: this.$t("daysAfter"),
          formatter: (value, a, b) => {
            if (value == 0) {
              return this.$t("firstApply");
            }
            return this.$t("afterTime", {
              day: b["daysafter"],
              hour: b["hoursafter"],
            });
          },
        },
        { key: "hormonename", label: this.$t("hormoneName") },
        { key: "defaultdoze", label: this.$t("doze") },
        { key: "description", label: this.$t("description") },
      ],
      steps: [],
    };
  },
  async mounted() {
    this.resetblanksynchronization();
  },
  methods: {
    async changed(val) {
      this.resetblanksynchronization();
      this.$emit("update:is-add-sync-modal-active", val);
    },
    onSubmit() {
      //if any value has iclude null
      if (
        this.blankSyncStepData &&
        this.blankSyncStepData.daysafter == null &&
        this.blankSyncStepData.hoursafter == null &&
        this.blankSyncStepData.defaultdoze == null
      ) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: this.$t("fillAllFields"),
          },
        });
        return;
      } else {
        this.blankSyncStepData.syncid = this.syncId;
        store
          .dispatch("definitionsModule/addSyncStep", this.blankSyncStepData)
          .then(() => {
            this.$emit("refetch-data");
            this.$emit("update:is-add-sync-modal-active", false);
          });
      }
    },
    async resetblanksynchronization() {
      this.blankSyncStepData = {
        syncid: this.syncId,
        methodid: this.methods[0]?.id ?? 0,
        hormonid: this.hormones[0]?.id ?? 0,
        daysafter: null,
        hoursafter: null,
        defaultdoze: null,
        description: "",
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-synchronization-modal {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
